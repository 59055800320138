import React, { useRef } from 'react';
import { CKEditor } from 'ckeditor4-react';
import { httpClient } from '../axios';
import { useLocation } from 'react-router-dom';
import { useState, useEffect } from 'react';

import { useParams } from 'react-router-dom';

const AddQuestions = () => {
  const [subjectList, setsubjectList] = useState([]);
  const [entExamsList, setEntExamsList] = useState([]);
  const [standardList, setStandardList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [subtopicList, setSubTopicList] = useState([]);

  let { userId, colorCode } = useParams();
  const location = useLocation();

  const { questionData } = location.state || {};

  useEffect(() => {
    console.log('questionObj:', questionData);
    setCreateQuestion((prev) => ({
      ...prev,
      id: questionData?.id || userId, // Keep the current user's ID if not present in obj
      entranceExamId: questionData?.entranceExamId || null,
      standardId: questionData?.standardId || null,
      subjectId: questionData?.subjectId || null,
      chapterId: questionData?.chapterId || null,
      marks: questionData?.marks || 0,
      topicId: questionData?.topicId || null,
      subTopicId: questionData?.subTopicId || null,
      question: questionData?.question || '',
      option1: questionData?.option1 || '',
      option2: questionData?.option2 || '',
      option3: questionData?.option3 || '',
      option4: questionData?.option4 || '',
      yearOfAppearanceId: questionData?.yearOfAppearanceId || null,
      questionLevelId: questionData?.questionLevelId || null,
      questionTypeId: questionData?.questionTypeId || null,
      multiAnswers: questionData?.multiAnswers || [],
      explanation: questionData?.explanation || '',
      status: questionData?.status || 'pending',
      patternId: questionData?.patternId || null,
      solution: questionData?.solution || '',
    }));
  }, [location.state]);

  const [createQuestion, setCreateQuestion] = useState({
    // questionId: null,
    id: userId,
    entranceExamId: null,
    standardId: null,
    subjectId: null,
    chapterId: null,
    marks: 0,
    topicId: null,
    subTopicId: null,
    question: '',
    option1: '',
    option2: '',
    option3: '',
    option4: '',
    yearOfAppearanceId: null,
    questionLevelId: null,
    questionTypeId: null,
    multiAnswers: [],
    explanation: '',
    status: 'pending',
    patternId: null,
    solution: '',
  });

  const handleSetQuestionDataForEdit = () => {};

  useEffect(() => {
    console.log('createQuestion', createQuestion);
  }, [createQuestion]);

  useEffect(() => {
    GetAllActiveEntExams();
  }, [createQuestion.standardId]);

  useEffect(() => {
    GetAllActiveSubjectsByStandardId();
    // console.log(createQuestion);
  }, [createQuestion.standardId]);

  useEffect(() => {
    GetChaptersBySubjectId();
    // console.log(createQuestion);
  }, [createQuestion.subjectId]);

  useEffect(() => {
    GetTopicsByChapterId();
    // console.log(createQuestion);
  }, [createQuestion?.chapterId]);

  useEffect(() => {
    GetSubTopicsByTopicId();
    // console.log(createQuestion);
  }, [createQuestion?.topicId]);

  const GetAllActiveSubjectsByStandardId = async () => {
    try {
      const res = await httpClient.get(
        `/api/subject/standardwisesubject/${createQuestion.standardId}`
      );
      setsubjectList(res?.data?.subjectMasters);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllActiveEntExams = async () => {
    try {
      const res = await httpClient.get(
        `/api/standard/standardwiseentranceexam/${createQuestion.standardId}`
      );
      setEntExamsList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetStandardsByEntranceId = async () => {
    try {
      const res = await httpClient.get(
        `api/standard/getallstandards`
      );
      setStandardList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetChaptersBySubjectId = async () => {
    try {
      const res = await httpClient.get(
        `/api/chapter/subjectwisechapter/${createQuestion?.subjectId}`
      );
      setChapterList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetTopicsByChapterId = async () => {
    try {
      const res = await httpClient.get(
        `/api/topic/chapterwisetopics/${createQuestion?.chapterId}`
      );
      setTopicList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const GetSubTopicsByTopicId = async () => {
    try {
      const res = await httpClient.get(
        `/api/subtopic/topicwisesubtopics/${createQuestion?.topicId}`
      );
      setSubTopicList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const [YearofAppearList, setYearofAppearList] = useState([]);
  const [QuestionTypeList, setQuestionTypeList] = useState([]);
  const [QuestionLevelList, setQuestionLevelList] = useState([]);
  const [PatternsList, setPatternsList] = useState([]);

  const GetYearOfAppearance = async () => {
    try {
      const res = await httpClient.get(
        `/api/yearofappearance/getallactive`
      );
      setYearofAppearList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetQuestionType = async () => {
    try {
      const res = await httpClient.get(
        `/api/questiontype/getallactive`
      );
      setQuestionTypeList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetQuestionLevel = async () => {
    try {
      const res = await httpClient.get(
        `/api/questionlevel/getallactive`
      );
      setQuestionLevelList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const GetAllActivePattern = async () => {
    try {
      const res = await httpClient.get(`/api/pattern/getallactive`);
      setPatternsList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  // Validation function
  const validateFields = () => {
    for (const key in createQuestion) {
      const value = createQuestion[key];
      if (
        value === null ||
        value === undefined ||
        value === '' ||
        (Array.isArray(value) && value.length === 0)
      ) {
        // alert(`Please fill in the ${key} field.`);
        alert(`Please fill in all the field.`);
        return false; // Return false if any field is invalid
      }
    }
    return true; // Return true if all fields are valid
  };

  const [loader, setloader] = useState(false);

  const PostQuestion = async () => {
    // if (!validateFields()) {
    //   return; // Exit the function if validation fails
    // }
    setloader(true);
    try {
      const res = await httpClient.post(
        `/api/question/create`,
        createQuestion
      );
      ResetData();
      alert('Question created successfully');
      console.log(res.data);
    } catch (error) {
      console.log(error);
    } finally {
      setloader(false);
    }
  };

  const ResetData = () => {
    // if (editorRef.current) {
    editorRef.current.setData(''); // Set editor content to empty
    editorRef1.current.setData('');
    editorRef2.current.setData('');
    editorRef3.current.setData('');
    editorRef4.current.setData('');
    editorRef5.current.setData('');
    // }
    // Set editor content to empty
    setCreateQuestion((prev) => ({
      ...prev,
      answer: '',
      question: '',
      option1: '',
      option2: '',
      option3: '',
      option4: '',
      explanation: '',
    }));
  };

  useEffect(() => {
    GetStandardsByEntranceId();
    // GetAllActiveEntExams();
    GetQuestionLevel();
    GetQuestionType();
    GetYearOfAppearance();
    GetAllActivePattern();
    // GetAllActiveStandards();
    // GetAllActiveSubjects();
  }, []);

  const editorRef = useRef(null);
  const editorRef1 = useRef(null);
  const editorRef2 = useRef(null);
  const editorRef3 = useRef(null);
  const editorRef4 = useRef(null);
  const editorRef5 = useRef(null);

  const [Data, setData] = useState();

  const config = {
    allowedContent: true,
    extraPlugins: 'image2,mathjax',
    removeButtons: 'Paste,PasteText,PasteFromWord',
    mathJaxLib:
      'https://cdnjs.cloudflare.com/ajax/libs/mathjax/2.7.9/MathJax.js?config=TeX-MML-AM_CHTML',
    versionCheck: false,
    height: '400px',
    // Image settings
    image2_alignClasses: [
      'image-align-left',
      'image-align-center',
      'image-align-right',
    ], // optional, for alignment
    image2_captionedClass: 'image-captioned',
    removePlugins: 'sourcearea',
    toolbar: [
      {
        name: 'clipboard',
        items: [
          'Cut',
          'Copy',
          'Paste',
          'PasteText',
          'PasteFromWord',
          '-',
          'Undo',
          'Redo',
        ],
      },
      {
        name: 'insert',
        items: ['Image', 'Table', 'HorizontalRule', 'SpecialChar'],
      },
      { name: 'styles', items: ['Styles', 'Format'] },
      {
        name: 'basicstyles',
        items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'],
      },
      {
        name: 'paragraph',
        items: [
          'NumberedList',
          'BulletedList',
          '-',
          'Outdent',
          'Indent',
          '-',
          'Blockquote',
        ],
      },
      { name: 'links', items: ['Link', 'Unlink', 'Anchor'] },
      { name: 'tools', items: ['Maximize'] },
    ],
  };

  function updateImageStyles(inputString) {
    // Create a temporary DOM element to parse the input string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = inputString;

    // Select all <img> tags in the temporary element
    const images = tempDiv.querySelectorAll('img');

    // Update the style of each <img> tag
    images.forEach((img) => {
      // Remove the existing style attribute
      img.removeAttribute('style');

      // Set new styles
      img.style.width = '300px';
      // img.style.height = '100px';
    });

    // Return the updated HTML as a string
    return tempDiv.innerHTML;
  }

  const handleOptionChange = (e) => {
    setCreateQuestion((prev) => ({
      ...prev,
      multiAnswers: [e.target.value],
    }));
  };

  const extractImageSrc = (htmlString) => {
    // Create a temporary div element to parse the HTML string
    const tempDiv = document.createElement('div');
    tempDiv.innerHTML = htmlString;

    // Get all <img> elements from the parsed HTML
    const imgElements = tempDiv.getElementsByTagName('img');

    // Extract the src attributes into an array
    const imgSrcArray = Array.from(imgElements).map((img) => img.src);

    return imgSrcArray;
  };

  // Function to convert base64 to Blob
  function base64ToBlob(base64Data, contentType) {
    const byteCharacters = atob(base64Data.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  // Function to convert an array of base64 strings to an array of Blobs
  function convertBase64ArrayToBlobs(base64Array, contentType) {
    return base64Array.map((base64String) =>
      base64ToBlob(base64String, contentType)
    );
  }

  function replaceImageSrc(uploadedUrls, data) {
    // Create a regular expression to match the img tags with src attributes
    const imgRegex = /<img[^>]+src="[^"]*"[^>]*>/g;

    // Find all img tags in the data string
    let imgTags = data.match(imgRegex);

    // Loop through each img tag and replace the src with the corresponding URL from uploadedUrls
    if (imgTags && uploadedUrls.length === imgTags.length) {
      imgTags.forEach((imgTag, index) => {
        const newImgTag = imgTag.replace(
          /src="[^"]*"/,
          `src="${uploadedUrls[index]}"`
        );
        data = data.replace(imgTag, newImgTag);
      });
    } else {
      console.warn(
        'The number of uploaded URLs and image tags do not match.'
      );
    }

    return data;
  }

  // Function to upload images

  const postImageApi = async (blobs, data, type) => {
    // Use Promise.all to wait for all file upload promises to resolve
    const uploadedUrls = await Promise.all(
      blobs.map(async (blob) => {
        let formData = new FormData();
        formData.append('file', blob);

        try {
          const res = await httpClient.post(
            '/api/v1/upload/file',
            formData,
            {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            }
          );

          console.log('File uploaded successfully');
          console.log(res);

          // Return the uploaded file URL from the response
          return res.data.url;
        } catch (err) {
          console.error('File upload failed', err);
          throw err; // Optional: You could handle failed uploads differently
        }
      })
    );
    console.log('uploadedUrls', uploadedUrls);
    console.log('data', data);

    let finalData = replaceImageSrc(uploadedUrls, data);

    // setarrayWithLinks(uploadedUrls);

    console.log('finalData', finalData);
    // console.log('finalData', finalData);

    // Add style for images to prevent stretching
    // data = data.replace(/<img>/g, '<img height: "19">');

    // Trim whitespace for cleaner inline formatting (optional)
    // finalData = finalData.replace(/\s+/g, ' ').trim();

    if (type === 'question') {
      setCreateQuestion((prev) => ({
        ...prev,
        question: finalData,
      }));
    } else if (type === 'option1') {
      setCreateQuestion((prev) => ({
        ...prev,
        option1: finalData,
      }));
    } else if (type === 'option2') {
      setCreateQuestion((prev) => ({
        ...prev,
        option2: finalData,
      }));
    } else if (type === 'option3') {
      setCreateQuestion((prev) => ({
        ...prev,
        option3: finalData,
      }));
    } else if (type === 'option4') {
      setCreateQuestion((prev) => ({
        ...prev,
        option4: finalData,
      }));
    } else if (type === 'explanation') {
      setCreateQuestion((prev) => ({
        ...prev,
        explanation: finalData,
      }));
    } else if (type === 'solution') {
      setCreateQuestion((prev) => ({
        ...prev,
        solution: finalData,
      }));
    }

    // setData(finalData);
    // Return an array of URLs after all uploads have finished
    // return uploadedUrls;
  };

  const options = [
    { id: 1, label: 'option1' },
    { id: 2, label: 'option2' },
    { id: 3, label: 'option3' },
    { id: 4, label: 'option4' },
  ];

  const [selectedOptions, setSelectedOptions] = useState([]);

  const handleToggle = (option) => {
    if (createQuestion?.multiAnswers?.includes(option)) {
      const tempArray = createQuestion?.multiAnswers.filter(
        (item) => item !== option
      );

      setCreateQuestion((prev) => ({
        ...prev,
        multiAnswers: tempArray,
      }));
    } else {
      setCreateQuestion((prev) => ({
        ...prev,
        multiAnswers: [...prev.multiAnswers, option],
      }));
    }
  };

  const SetMarks = (e) => {
    let val = Number(e.target.value); // Convert to number

    setCreateQuestion((prev) => ({
      ...prev,
      marks: val,
    }));
  };
  const SetPattern = (e) => {
    let val = e.target.value; // Convert to number

    setCreateQuestion((prev) => ({
      ...prev,
      patternId: val,
    }));
  };

  // const [editorContent, setEditorContent] = useState(
  //   createQuestion?.question || ''
  // ); // Initialize with question or empty string
  // // const editorRef = useRef();

  // useEffect(() => {
  //   // Update the editor content when createQuestion changes
  //   if (createQuestion?.question) {
  //     setEditorContent(createQuestion.question);
  //   }
  // }, [createQuestion]);

  return (
    <>
      <a
        href='http://elitecodo.zplusglobalmarketinsights.com/teacher/cet/dashboard'
        style={{ textDecoration: 'none' }}
      >
        <button
          // style={{
          //   background:
          //     '-webkit-linear-gradient(left, #73b0df, #7e96d2, #9862b8)',
          // }}
          // onClick={PostQuestion}
          className='px-[20px] absolute top-[15px] text-[#333333] left-[15px] h-[44px] bg-[#DE3163] rounded-[5000px] font-medium flex justify-center items-center text-[18px] text-[#ffffff]'
        >
          Back
        </button>
      </a>

      <div
        style={{
          background:
            // '-webkit-linear-gradient(left, #73b0df, #7e96d2, #9862b8)',
            `#${colorCode}`,
        }}
        className='App p-[50px]'
      >
        <div
          style={{
            boxShadow:
              ' rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px',
          }}
          // style={{
          //   borderTopLeftRadius: '10% 50%',
          //   borderBottomLeftRadius: '10% 50%',
          // }}
          className='w-[100%] max-w-[1098px] bg-[#f8f9fa] rounded-[40px] mx-[auto]'
        >
          <p className='text-[28px] text-[#757575] font-thin text-center pt-[16px]'>
            Submit your{' '}
            <span className='text-[#4E54C8] font-light underline'>
              Questions
            </span>
          </p>
          <div className='p-[40px]'>
            <div className='max-w-[1024px] mx-[auto] w-[100%] flex flex-wrap justify-center gap-[30px]'>
              <div className='flex flex-col'>
                <p className='text-[#757575]'>Standard Name</p>
                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.standardId || ''} // Set the default selected value here
                  onChange={(e) => {
                    const selectedStandardId = e.target.value; // Get the selected standardId
                    console.log('Selected ID:', selectedStandardId); // Debugging log

                    const selectedStandard = standardList.find(
                      (exam) =>
                        exam.standardId === Number(selectedStandardId) // Convert to number for matching
                    );

                    console.log(
                      'Selected Standard:',
                      selectedStandard
                    ); // Debugging log

                    if (selectedStandard) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        standardId: selectedStandard.standardId, // Ensure standardId is being set correctly
                      }));
                    }
                  }}
                >
                  <option style={{ color: '#9ca3af' }} value=''>
                    Select Standard
                  </option>

                  {standardList?.map((obj, index) => (
                    <option key={index} value={obj.standardId}>
                      {obj.standardName}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Exam Name</p>
                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.entranceExamId || ''} // Set the default selected value here
                  onChange={(e) => {
                    const selectedExamName = e.target.value;
                    const selectedExam = entExamsList?.find(
                      (exam) =>
                        exam.entranceExamId ===
                        Number(selectedExamName)
                    );

                    if (selectedExam) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        entranceExamId: selectedExam.entranceExamId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select exam</option>

                  {entExamsList?.length > 0 &&
                    entExamsList?.map((obj, index) => (
                      <option key={index} value={obj.entranceExamId}>
                        {obj.entranceExamName}
                      </option>
                    ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Subject Name</p>
                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.subjectId || ''} // Set the default selected value here
                  onChange={(e) => {
                    const selectedSubjectName = e.target.value;

                    const selectedSubject = subjectList.find(
                      (exam) =>
                        exam.subjectId === Number(selectedSubjectName)
                    );

                    if (selectedSubject) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        subjectId: selectedSubject.subjectId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Subject</option>

                  {subjectList?.map((obj, index) => (
                    <option key={index} value={obj.subjectId}>
                      {obj.subjectName}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Chapter Name</p>

                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.chapterId || ''} // Set the default selected value here
                  onChange={(e) => {
                    const selectedChapterName = e.target.value;

                    const selectedChapter = chapterList.find(
                      (exam) =>
                        exam.chapterId === Number(selectedChapterName)
                    );

                    if (selectedChapter) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        chapterId: selectedChapter.chapterId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Chapter</option>

                  {chapterList?.map((obj, index) => (
                    <option key={index} value={obj.chapterId}>
                      {obj.chapterName}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Topic name</p>
                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.topicId || ''} // Set the default selected value here
                  onChange={(e) => {
                    const selectedTopicName = e.target.value;

                    const selectedTopic = topicList.find(
                      (exam) =>
                        exam.topicId === Number(selectedTopicName)
                    );

                    if (selectedTopic) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        topicId: selectedTopic.topicId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Topic</option>

                  {topicList?.map((obj, index) => (
                    <option key={index} value={obj.topicId}>
                      {obj.topicName}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Sub Topic Name</p>
                <select
                  className='form-select w-[300px]'
                  value={createQuestion?.subTopicId || ''} // Set the default selected value here
                  aria-label='Default select example'
                  onChange={(e) => {
                    const selectedSubTopicName = e.target.value;

                    const selectedsubTopic = subtopicList.find(
                      (exam) =>
                        exam.subTopicId ===
                        Number(selectedSubTopicName)
                    );

                    if (selectedsubTopic) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        subTopicId: selectedsubTopic.subTopicId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select SubTopic</option>

                  {subtopicList?.map((obj, index) => (
                    <option key={index} value={obj.subTopicId}>
                      {obj.subTopicName}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Year Of Appearance</p>
                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.yearOfAppearanceId || ''}
                  onChange={(e) => {
                    const selectedYearOfAppearance = e.target.value;

                    const selectedyearOfAppearance =
                      YearofAppearList.find(
                        (exam) =>
                          exam.yearOfAppearanceId ===
                          Number(selectedYearOfAppearance)
                      );

                    if (selectedyearOfAppearance) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        yearOfAppearanceId:
                          selectedyearOfAppearance.yearOfAppearanceId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Year of Appearance</option>

                  {YearofAppearList?.map((obj, index) => (
                    <option
                      key={index}
                      value={obj?.yearOfAppearanceId}
                    >
                      {obj?.yearOfAppearance}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Question type</p>
                <select
                  className='form-select w-[300px]'
                  aria-label='Default select example'
                  value={createQuestion?.questionTypeId || ''}
                  onChange={(e) => {
                    const selectedQuestionType = e.target.value;

                    const selectedquestionType =
                      QuestionTypeList.find(
                        (exam) =>
                          exam.questionTypeId ===
                          Number(selectedQuestionType)
                      );

                    if (selectedquestionType) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        questionTypeId:
                          selectedquestionType.questionTypeId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Type of Question</option>

                  {QuestionTypeList?.map((obj, index) => (
                    <option key={index} value={obj?.questionTypeId}>
                      {obj?.questionType}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Level of Question</p>
                <select
                  className='form-select w-[300px]'
                  value={createQuestion?.questionLevelId || ''}
                  aria-label='Default select example'
                  onChange={(e) => {
                    const selectedQuestionLevel = e.target.value;

                    const selectedquestionLevel =
                      QuestionLevelList?.find(
                        (exam) =>
                          exam.questionLevelId ===
                          Number(selectedQuestionLevel)
                      );

                    if (selectedquestionLevel) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        questionLevelId:
                          selectedquestionLevel.questionLevelId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Level of Question</option>

                  {QuestionLevelList?.map((obj, index) => (
                    <option key={index} value={obj?.questionLevelId}>
                      {obj?.questionLevel}
                    </option>
                  ))}
                </select>
              </div>

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Marks</p>
                <input
                  className='h-[38px] w-[300px] px-[10px]  border border-[#ced4da]'
                  type='number'
                  placeholder='Enter marks'
                  onChange={SetMarks}
                  value={createQuestion?.marks}
                />
              </div>
              {/* <div className='flex flex-col'>
                <p className='text-[#757575]'>Pattern</p>
                <input
                  className='h-[38px] w-[300px] px-[10px] border border-[#ced4da]'
                  type='text'
                  placeholder='Enter pattern'
                  onChange={SetPattern}
                />
              </div> */}

              <div className='flex flex-col'>
                <p className='text-[#757575]'>Pattern</p>
                <select
                  className='form-select w-[300px]'
                  value={createQuestion?.patternId || ''}
                  aria-label='Default select example'
                  onChange={(e) => {
                    const selectedPattern = e.target.value;

                    const selectedpattern = PatternsList?.find(
                      (exam) =>
                        exam.patternId === Number(selectedPattern)
                    );

                    if (selectedpattern) {
                      setCreateQuestion((prev) => ({
                        ...prev,
                        patternId: selectedpattern.patternId,
                      }));
                    }
                  }}
                >
                  <option value=''>Select Pattern</option>

                  {PatternsList?.map((obj, index) => (
                    <option key={index} value={obj?.patternId}>
                      {obj?.patternName}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className='mt-[30px]'>
              <p className='text-[#757575]'>Question</p>
              <CKEditor
                // config={config}
                // initData={editorContent}
                // data={} // Bind the data prop to the state
                onChange={(event) => {
                  let dataa = event.editor.getData();

                  let data = dataa;
                  // let data = updateImageStyles(dataa);

                  console.log('data', data);

                  // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                  data = data
                    .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                    .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                    .replace(
                      /<li>/g,
                      `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                    ) // Replace <li> with inline <span>
                    .replace(
                      /<p>/g,
                      `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                    ); // Replace <li> with inline <span>

                  const imageSources = extractImageSrc(data);

                  // Convert base64 array to Blob array
                  const blobs = convertBase64ArrayToBlobs(
                    imageSources,
                    'image/png'
                  );

                  const res = postImageApi(blobs, data, 'question');
                }}
                config={{
                  ...config,
                  // maxWidth: '400px',
                  // width: '100%',
                  height: '400px',
                }}
                // config={{
                //   extraPlugins: 'image2',
                //   removeButtons: 'Paste,PasteText,PasteFromWord',
                //   removePlugins: 'sourcearea',
                // }}
                onInstanceReady={(event) => {
                  editorRef.current = event.editor;

                  // Hide all .cke_top elements
                  const topEditorElements =
                    document.querySelectorAll('.cke_top');
                  topEditorElements.forEach((editorElement) => {
                    editorElement.style.display = 'none';
                  });

                  const bottomEditorElements =
                    document.querySelectorAll('.cke_bottom');
                  bottomEditorElements.forEach((editorElementt) => {
                    editorElementt.style.display = 'none';
                  });
                }}
              />

              <div className='flex flex-col gap-[20px] mt-[30px] '>
                <div className='flex w-[100%] items-between flex-wrap justify-center  gap-[20px]'>
                  <div className='flex flex-col w-[100%] max-w-[498px]'>
                    <p className='text-[#757575]'>Option 1</p>

                    <CKEditor
                      initData=''
                      onChange={(event) => {
                        let dataa = event.editor.getData();

                        let data = dataa;
                        // let data = updateImageStyles(dataa);

                        console.log('data', data);

                        // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                        data = data
                          .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                          .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                          .replace(
                            /<li>/g,
                            `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ) // Replace <li> with inline <span>
                          .replace(
                            /<p>/g,
                            `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ); // Replace <li> with inline <span>

                        const imageSources = extractImageSrc(data);

                        // Convert base64 array to Blob array
                        const blobs = convertBase64ArrayToBlobs(
                          imageSources,
                          'image/png'
                        );

                        const res = postImageApi(
                          blobs,
                          data,
                          'option1'
                        );
                      }}
                      config={{
                        ...config,

                        width: '100%',
                        height: '300px',
                      }}
                      onInstanceReady={(event) => {
                        editorRef1.current = event.editor;

                        // Hide all .cke_top elements
                        const topEditorElements =
                          document.querySelectorAll('.cke_top');
                        topEditorElements.forEach((editorElement) => {
                          editorElement.style.display = 'none';
                        });

                        const bottomEditorElements =
                          document.querySelectorAll('.cke_bottom');
                        bottomEditorElements.forEach(
                          (editorElementt) => {
                            editorElementt.style.display = 'none';
                          }
                        );
                      }}
                    />
                  </div>

                  <div className='flex flex-col  w-[100%] max-w-[498px]'>
                    <p className='text-[#757575]'>Option 2</p>
                    <CKEditor
                      initData=''
                      onChange={(event) => {
                        let dataa = event.editor.getData();

                        let data = dataa;
                        // let data = updateImageStyles(dataa);

                        console.log('data', data);

                        // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                        data = data
                          .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                          .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                          .replace(
                            /<li>/g,
                            `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ) // Replace <li> with inline <span>
                          .replace(
                            /<p>/g,
                            `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ); // Replace <li> with inline <span>

                        const imageSources = extractImageSrc(data);

                        // Convert base64 array to Blob array
                        const blobs = convertBase64ArrayToBlobs(
                          imageSources,
                          'image/png'
                        );

                        const res = postImageApi(
                          blobs,
                          data,
                          'option2'
                        );
                      }}
                      config={{
                        ...config,
                        // maxWidth: '500px',
                        width: '100%',
                        height: '300px',
                      }}
                      onInstanceReady={(event) => {
                        editorRef2.current = event.editor;

                        // Hide all .cke_top elements
                        const topEditorElements =
                          document.querySelectorAll('.cke_top');
                        topEditorElements.forEach((editorElement) => {
                          editorElement.style.display = 'none';
                        });

                        const bottomEditorElements =
                          document.querySelectorAll('.cke_bottom');
                        bottomEditorElements.forEach(
                          (editorElementt) => {
                            editorElementt.style.display = 'none';
                          }
                        );
                      }}
                    />
                  </div>
                </div>
                <div className='flex items-between  flex-wrap justify-center gap-[20px]'>
                  <div className='flex flex-col  w-[100%] max-w-[498px]'>
                    <p className='text-[#757575]'>Option 3</p>
                    <CKEditor
                      initData=''
                      onChange={(event) => {
                        let dataa = event.editor.getData();

                        let data = dataa;
                        // let data = updateImageStyles(dataa);

                        console.log('data', data);

                        // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                        data = data
                          .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                          .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                          .replace(
                            /<li>/g,
                            `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ) // Replace <li> with inline <span>
                          .replace(
                            /<p>/g,
                            `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ); // Replace <li> with inline <span>

                        const imageSources = extractImageSrc(data);

                        // Convert base64 array to Blob array
                        const blobs = convertBase64ArrayToBlobs(
                          imageSources,
                          'image/png'
                        );

                        const res = postImageApi(
                          blobs,
                          data,
                          'option3'
                        );
                      }}
                      config={{
                        ...config,
                        // maxWidth: '400px',
                        width: '100%',
                        height: '300px',
                      }}
                      // config={{
                      //   extraPlugins: 'image2',
                      //   removeButtons: 'Paste,PasteText,PasteFromWord',
                      //   removePlugins: 'sourcearea',
                      // }}
                      onInstanceReady={(event) => {
                        editorRef3.current = event.editor;

                        // Hide all .cke_top elements
                        const topEditorElements =
                          document.querySelectorAll('.cke_top');
                        topEditorElements.forEach((editorElement) => {
                          editorElement.style.display = 'none';
                        });

                        const bottomEditorElements =
                          document.querySelectorAll('.cke_bottom');
                        bottomEditorElements.forEach(
                          (editorElementt) => {
                            editorElementt.style.display = 'none';
                          }
                        );
                      }}
                    />
                  </div>

                  <div className='flex flex-col  w-[100%] max-w-[498px]'>
                    <p className='text-[#757575]'>Option 4</p>
                    <CKEditor
                      initData=''
                      onChange={(event) => {
                        let dataa = event.editor.getData();

                        let data = dataa;
                        // let data = updateImageStyles(dataa);

                        console.log('data', data);

                        // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                        data = data
                          .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                          .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                          .replace(
                            /<li>/g,
                            `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ) // Replace <li> with inline <span>
                          .replace(
                            /<p>/g,
                            `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                          ); // Replace <li> with inline <span>

                        const imageSources = extractImageSrc(data);

                        // Convert base64 array to Blob array
                        const blobs = convertBase64ArrayToBlobs(
                          imageSources,
                          'image/png'
                        );

                        const res = postImageApi(
                          blobs,
                          data,
                          'option4'
                        );
                      }}
                      config={{
                        ...config,
                        // maxWidth: '400px',
                        width: '100%',
                        height: '300px',
                      }}
                      onInstanceReady={(event) => {
                        editorRef4.current = event.editor;

                        // Hide all .cke_top elements
                        const topEditorElements =
                          document.querySelectorAll('.cke_top');
                        topEditorElements.forEach((editorElement) => {
                          editorElement.style.display = 'none';
                        });

                        const bottomEditorElements =
                          document.querySelectorAll('.cke_bottom');
                        bottomEditorElements.forEach(
                          (editorElementt) => {
                            editorElementt.style.display = 'none';
                          }
                        );
                      }}
                    />
                  </div>
                </div>
              </div>

              {createQuestion.questionTypeId === 1 ? (
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='mx-[auto]'
                >
                  <p className='mt-[30px]'>Select your Answer</p>

                  <div className='max-w-[600px] mt-[20px] flex-wrap  w-[100%] flex gap-[30px]'>
                    <div className='flex'>
                      <input
                        type='radio'
                        id='option1'
                        name='options'
                        value='option1'
                        checked={
                          createQuestion.multiAnswers[0] === 'option1'
                        }
                        onChange={handleOptionChange}
                      />
                      <label htmlFor='option1'> &nbsp;Option 1</label>
                    </div>

                    <div className='flex'>
                      <div>
                        <input
                          type='radio'
                          id='option2'
                          name='options'
                          value='option2'
                          checked={
                            createQuestion.multiAnswers[0] ===
                            'option2'
                          }
                          onChange={handleOptionChange}
                        />
                        <label htmlFor='option2'>
                          {' '}
                          &nbsp;Option 2
                        </label>
                      </div>
                    </div>

                    <div className='flex'>
                      <div>
                        <input
                          type='radio'
                          id='option3'
                          name='options'
                          value='option3'
                          checked={
                            createQuestion.multiAnswers[0] ===
                            'option3'
                          }
                          onChange={handleOptionChange}
                        />
                        <label htmlFor='option3'>
                          {' '}
                          &nbsp;Option 3
                        </label>
                      </div>
                    </div>

                    <div className='flex'>
                      <div>
                        <input
                          type='radio'
                          id='option4'
                          name='options'
                          value='option4'
                          checked={
                            createQuestion.multiAnswers[0] ===
                            'option4'
                          }
                          onChange={handleOptionChange}
                        />
                        <label htmlFor='option4'>
                          {' '}
                          &nbsp;Option 4
                        </label>
                      </div>
                    </div>
                  </div>

                  <p className='mt-[20px]'>
                    Selected option: {createQuestion.answer}
                  </p>
                </div>
              ) : createQuestion.questionTypeId === 2 ? (
                <div
                  style={{
                    width: 'fit-content',
                  }}
                  className='mx-auto mt-[30px]'
                >
                  <p className='mt-[30px]'>Select Multiple Options</p>

                  <div className='max-w-[600px] mt-[20px] flex-wrap w-[100%] flex gap-[30px]'>
                    {options?.map((option) => (
                      <div
                        key={option.id}
                        className='flex items-center'
                      >
                        <label>
                          <input
                            type='checkbox'
                            checked={createQuestion?.multiAnswers?.includes(
                              option.label
                            )}
                            onChange={() =>
                              handleToggle(option.label)
                            }
                          />{' '}
                          {option.label}
                        </label>
                      </div>
                    ))}
                  </div>
                  <p className='mt-[20px]'>
                    Selected Options:{' '}
                    {createQuestion?.multiAnswers?.join(', ')}
                  </p>
                </div>
              ) : (
                <>
                  <p className='mt-[30px] text-[#757575]'>Solution</p>
                  <CKEditor
                    initData=''
                    onChange={(event) => {
                      let dataa = event.editor.getData();

                      let data = dataa;
                      // let data = updateImageStyles(dataa);

                      console.log('data', data);

                      // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                      data = data
                        .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                        .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                        .replace(
                          /<li>/g,
                          `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                        ) // Replace <li> with inline <span>
                        .replace(
                          /<p>/g,
                          `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                        ); // Replace <li> with inline <span>

                      const imageSources = extractImageSrc(data);

                      // Convert base64 array to Blob array
                      const blobs = convertBase64ArrayToBlobs(
                        imageSources,
                        'image/png'
                      );

                      const res = postImageApi(
                        blobs,
                        data,
                        'solution'
                      );
                    }}
                    config={config}
                    onInstanceReady={(event) => {
                      editorRef5.current = event.editor;

                      // Hide all .cke_top elements
                      const topEditorElements =
                        document.querySelectorAll('.cke_top');
                      topEditorElements.forEach((editorElement) => {
                        editorElement.style.display = 'none';
                      });

                      const bottomEditorElements =
                        document.querySelectorAll('.cke_bottom');
                      bottomEditorElements.forEach(
                        (editorElementt) => {
                          editorElementt.style.display = 'none';
                        }
                      );
                    }}
                  />
                </>
              )}

              <p className='mt-[30px] text-[#757575]'>Explanation</p>
              <CKEditor
                initData=''
                onChange={(event) => {
                  let dataa = event.editor.getData();

                  let data = dataa;
                  // let data = updateImageStyles(dataa);

                  console.log('data', data);

                  // Replace <ol> and <li> tags with <span> tags and ensure inline behavior
                  data = data
                    .replace(/<\/?ol>/g, '') // Remove <ol> and </ol> tags
                    .replace(/<\/li>/g, '</span>') // Replace </li> with </span>
                    .replace(
                      /<li>/g,
                      `<span style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                    ) // Replace <li> with inline <span>
                    .replace(
                      /<p>/g,
                      `<p style="display: flex; flex-wrap: wrap; white-space: nowrap;">`
                    ); // Replace <li> with inline <span>

                  const imageSources = extractImageSrc(data);

                  // Convert base64 array to Blob array
                  const blobs = convertBase64ArrayToBlobs(
                    imageSources,
                    'image/png'
                  );

                  const res = postImageApi(
                    blobs,
                    data,
                    'explanation'
                  );
                }}
                config={config}
                onInstanceReady={(event) => {
                  editorRef5.current = event.editor;

                  // Hide all .cke_top elements
                  const topEditorElements =
                    document.querySelectorAll('.cke_top');
                  topEditorElements.forEach((editorElement) => {
                    editorElement.style.display = 'none';
                  });

                  const bottomEditorElements =
                    document.querySelectorAll('.cke_bottom');
                  bottomEditorElements.forEach((editorElementt) => {
                    editorElementt.style.display = 'none';
                  });
                }}
              />
            </div>

            <div
              style={{
                width: 'fit-content',
              }}
              className='flex gap-[10px] mx-[auto]'
            >
              <button
                style={{
                  background:
                    '-webkit-linear-gradient(left, #8F94FB, #4E54C8)',
                }}
                onClick={() => {
                  if (!loader) {
                    PostQuestion();
                  }
                }}
                className={`${
                  loader ? 'disabled' : ''
                } px-[20px] h-[44px] rounded-[5000px] font-medium flex mt-[32px] justify-center font-light items-center text-[18px] text-[#ffffff]`}
              >
                <i
                  className={`fa ${
                    loader ? 'fa-spin fa-spinner' : ''
                  }`}
                ></i>
                {loader ? 'Processing' : 'Submit'}
                {/* Submit */}
              </button>
            </div>

            <div
              style={{
                display: 'flex',
                flexWrap: 'wrap',
                whiteSpace: 'nowrap',
              }}
              dangerouslySetInnerHTML={{
                __html: createQuestion.question,
              }}
              className='mt-[100px]'
            ></div>
            <div className='flex gap-[10px] mt-[50px]'>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  whiteSpace: 'nowrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: createQuestion.option1,
                }}
                className='w-[500px]'
              ></div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  whiteSpace: 'nowrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: createQuestion.option2,
                }}
                className='w-[500px]'
              ></div>
            </div>
            <div className='flex gap-[10px]'>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  whiteSpace: 'nowrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: createQuestion.option3,
                }}
                className='w-[500px]'
              ></div>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  whiteSpace: 'nowrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: createQuestion.option4,
                }}
                className='w-[500px]'
              ></div>
            </div>

            <div className='flex gap-[10px]'>
              <div
                style={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  whiteSpace: 'nowrap',
                }}
                dangerouslySetInnerHTML={{
                  __html: createQuestion.explanation,
                }}
                className='w-[1000px]'
              ></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddQuestions;
