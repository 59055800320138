import React from 'react';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from 'react-router-dom';
import AddQuestions from './components/AddQuestions';
import Login from './components/Login';
import ViewQuestions from './components/ViewQuestions';

const App = () => {
  return (
    <Router>
      <div className='App'>
        <Routes>
          {/* Set AddQuestions as the default component for the index route */}
          <Route
            path='/'
            element={<Navigate to='/create-question' />}
          />
          <Route
            path='/create-question/:colorCode/:userId'
            element={<AddQuestions />}
          />
          <Route path='/login' element={<Login />} />
          <Route
            path='/question-list/:userId'
            element={<ViewQuestions />}
          />
        </Routes>
      </div>
    </Router>
  );
};

export default App;
