import React, { useState, useEffect } from 'react';
import { httpClient } from '../axios';
import { useParams } from 'react-router-dom';
import { useLocation, useNavigate } from 'react-router-dom';

const ViewQuestions = () => {
  const [questionList, setQuestionList] = useState([]);
  const [open, setOpen] = useState(false);
  const [questionToDelete, setQuestionToDelete] = useState(null);
  const navigate = useNavigate();

  let { userId } = useParams();

  const [filtertext, setFiltertext] = useState('');

  const GetActiveQuestions = async () => {
    try {
      const res = await httpClient.get(
        `api/question/getByUserId/${userId}/pending`
      );
      setQuestionList(res.data);
      console.log(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleDelete = async () => {
    try {
      const res = await httpClient.delete(
        `api/question/delete/${questionToDelete}`
      );
      GetActiveQuestions();
      console.log(res.data);
      // setQuestionList((prevList) =>
      //   prevList.filter((question) => question.questionId !== questionToDelete)
      // );
      setOpen(false);
    } catch (error) {
      console.log('Error deleting question:', error);
    }
  };

  const handleClickOpen = (questionId) => {
    setQuestionToDelete(questionId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setQuestionToDelete(null);
  };

  // Navigate to AddQuestion page with the current question object
  const handleEdit = (obj) => {
    navigate(`/create-question/${userId}`, {
      state: { questionData: obj },
    });
  };

  useEffect(() => {
    GetActiveQuestions();
  }, []);

  useEffect(() => {
    handleDelete();
  }, []);

  const handleFilterChange = (e) => {
    setFiltertext(e.target.value);
  };

  const filteredQuestions = questionList.filter((question) =>
    question.question.toLowerCase().includes(filtertext.toLowerCase())
  );
  return (
    <>
      <p
        className='text-[28px]
       text-[#757575]
       font-thin text-center
        py-[16px]'
      >
        View{' '}
        <span className='text-[#4E54C8] font-light underline'>
          Questions
        </span>
      </p>

      {/* filter fild */}
      <div className='px-[10px] py-[10px] flex justify-end fixed top-0 right-0'>
        <input
          type='text'
          placeholder='Filter questions...'
          value={filtertext}
          onChange={handleFilterChange}
          className='border border-gray-300 p-[10px] rounded-md w-[100%] mr-48 mt-[10px]'
        />
      </div>
      <div className='px-[10px]'>
        {filteredQuestions?.map((obj) => (
          <>
            <div
              className='flex flex-col
             border border-[#EFEFEF]
              text-[14px] py-[25px]'
              style={{
                display: 'flex ',
                width: '100%',
              }}
              key={obj.questionId}
            >
              <div
                className='flex items-center
             gap-[25px]
             p-[15px]'
              >
                <div style={{ fontSize: '14px' }}>
                  {obj.questionId}
                </div>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    gap: '15px',
                  }}
                >
                  {/* <div>
                    
                  </div> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: obj.question,
                    }}
                    className='max-w-[1070px] w-[100%]'
                  ></div>
                  <div
                    style={{
                      maxWidth: '850px',
                      width: '100%',
                      display: 'flex',
                      flexWrap: 'wrap',
                      gap: '28px',
                    }}
                  >
                    {['option1', 'option2', 'option3', 'option4'].map(
                      (option, index) => (
                        <div
                          key={index}
                          className={`p-[8px] flex-wrap`}
                          style={{
                            width: '400px',
                            border: '1.5px solid',
                            borderColor: obj.multiAnswers?.includes(
                              option
                            )
                              ? '#22C55E'
                              : '#EFEFEF',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                          dangerouslySetInnerHTML={{
                            __html: obj[option],
                          }}
                        />
                      )
                    )}
                  </div>
                  <p>Explanation:</p>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: obj.explanation,
                    }}
                  ></p>

                  <div>
                    <span>
                      Exam: {obj?.entranceExamName} (
                      {obj?.yearOfAppearance})
                    </span>{' '}
                    <br />
                    <span>Subject: {obj?.subjectName}</span>
                  </div>
                </div>
                <div
                  style={{
                    flex: 1,
                    display: 'flex',
                    justifyContent: 'space-between',
                    marginRight: '15px',
                  }}
                >
                  <div></div>
                  <div style={{ display: 'flex' }}>
                    <div
                      style={{
                        height: '30px',
                        width: '35px',
                        border: '2px solid #EFEFEF',
                        borderRadius: '10px 0 0 10px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleEdit(obj)} // Navigate to AddQuestion page
                    >
                      <i className='fa-solid fa-pen-to-square'></i>
                    </div>
                    <div
                      style={{
                        height: '30px',
                        width: '35px',
                        border: '2px solid #EFEFEF',
                        borderRadius: '0px 10px 10px 0px',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        cursor: 'pointer',
                      }}
                      onClick={() => handleClickOpen(obj.questionId)} // Open the delete confirmation popup
                    >
                      <i className='fa-solid fa-trash'></i>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Delete Confirmation Popup */}
            <div>
              {open && (
                <div
                  style={{
                    position: 'fixed',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                    // boxShadow:
                    //   'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px',
                    backgroundColor: 'rgba(0,0,0,0.2)',
                    // opacity: '50%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      backgroundColor: 'white',
                      padding: '20px',
                      borderRadius: '10px',
                      width: '400px',
                      textAlign: 'center',
                    }}
                  >
                    <h2>Confirm Delete</h2>
                    <p>
                      Are you sure you want to delete this question?
                    </p>
                    <div
                      style={{
                        marginTop: '20px',
                        display: 'flex',
                        justifyContent: 'space-around',
                      }}
                    >
                      <button
                        onClick={handleClose}
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#EFEFEF',
                          borderRadius: '5px',
                        }}
                      >
                        Cancel
                      </button>
                      <button
                        onClick={() => {
                          handleDelete(obj);
                        }}
                        style={{
                          padding: '10px 20px',
                          backgroundColor: '#F44336',
                          color: 'white',
                          borderRadius: '5px',
                        }}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </>
        ))}
      </div>
    </>
  );
};
export default ViewQuestions;
